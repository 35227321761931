<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">申请</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="申请编号" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.code || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学期" align="center" min-width="120">
				  <template slot-scope="scope">
					<span>{{ scope.row.year +' '+ scope.row.tag}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="学科" align="center" min-width="100">
				  <template slot-scope="scope">
					<span>{{ scope.row.xueke_name || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
				  <template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="success">已审核</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="warning">待审核</el-tag>
						<el-tag v-if="scope.row.status === 3" size="mini" type="danger">驳回</el-tag>
				  </template>
				</el-table-column>
				<el-table-column label="分数" min-width="110" align="center" >
				  <template slot-scope="scope">
					<span>{{ scope.row.score || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column label="反馈意见" min-width="110" align="center" :show-overflow-tooltip="true">
				  <template slot-scope="scope">
					<span>{{ scope.row.remarks || ''}}</span>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="160">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top" v-if="scope.row.status != 1">
					  <el-button type="primary" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="scope.row.status != 1">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="学期" prop="s_id">
		      <el-select v-model="form.s_id" placeholder="请选择学期" style="width: 100%;">
		        <el-option :value="item.id" :label="item.year+' 学期'" v-for="(item,index) in semester" :key="index"/>
		      </el-select>
		    </el-form-item>
			<el-form-item label="学科" prop="xueke">
			  <el-select v-model="form.xueke" placeholder="请选择学科" style="width: 100%;">
			    <el-option :value="item.id" :label="item.name" v-for="(item,index) in xuekes" :key="index"/>
			  </el-select>
			</el-form-item>
		    <el-form-item label="分数指标">
		      <div class="w-100 flex flex-column">
		      	<div class="w-100 mb-2">
		      		按照日常考勤进行评价，给出评价分0-1分，每缺勤一次扣0.2分。
		      	</div>
		      </div>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'user_aszd',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					s_id:'',
					xueke:'',
				},
				rules:{
					s_id: [
					  { required: true, message: '请选择学期', trigger: ['blur', 'change'] }
					],
					xueke: [
					  { required: true, message: '请选择学科', trigger: ['blur', 'change'] }
					],
				},
				xuekes:[
					{id:2,name:'数学'},
					{id:4,name:'物理'},
					{id:5,name:'化学'},
					{id:6,name:'生物'},
					{id:13,name:'信息技术'},
				],
			}
		},
		mounted() {
			this.getsemester()
		},
		methods:{
			reset() {
			  this.form = {
			    s_id:'',
				xueke:'',
			  }
			  this.resetForm('form')
			},
		}
	}
</script>

<style>
</style>